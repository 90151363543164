
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TasksSidebar from '@/components/pages/tasks/TasksSidebar.vue'

export default defineComponent({
  components: {
    TmButton,
    TasksSidebar,
  },
})

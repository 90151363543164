
import { computed, defineComponent } from 'vue'
import PageContentSidebarList from '@/components/layout/pageContent/sidebar/PageContentSidebarList.vue'
import PageContentSidebarHr from '@/components/layout/pageContent/sidebar/PageContentSidebarHr.vue'
import PageContentSidebarListTitle from '@/components/layout/pageContent/sidebar/sidebarItem/PageContentSidebarListTitle.vue'
import { tasksSidebarRoutes } from '@/router/sidebarRoutes/tasksRoutes'
import TmButton from '@/components/shared/TmButton.vue'
import { useRouter } from 'vue-router'
import { useModals } from '@/compositions/modals'
import { useModes } from '@/compositions/modes'
import type { PageContentSidebarItem } from '@/definitions/_layout/pageContent/sidebar/types'
import useTasks from '@/compositions/tasks/useTasks'

export default defineComponent({
  components: {
    TmButton,
    PageContentSidebarList,
    PageContentSidebarHr,
    PageContentSidebarListTitle,
  },
  setup() {
    const { isEmptyMode, isEmptyHalfMode } = useModes()
    const { openModal } = useModals()
    const { openTemplateModal } = useTasks()
    const router = useRouter()
    const actionsMap: Record<string, () => void> = {
      edit: () => openModal('tasksEditDetails'),
      viewDetails: () => router.push({ name: 'base.tasks.settings.detailed' }),
      archive: () => openModal('confirmation', {
        title: 'Archive',
        text: [
          'By archiving the ',
          {
            text: 'Marketing',
            style: 'semi-bold',
          },
          ' board, you don\'t delete tasks from it, but users won\'t have access to them until you unarchive the board.',
        ],
        btnText: 'Archive',
      }),
    }

    const actionClick = (name: string) => {
      if (actionsMap[name]) actionsMap[name]()
    }
    const tasksSidebarRoutesComputed = computed(() => {
      if (isEmptyHalfMode.value) {
        return tasksSidebarRoutes.slice(0, 1).map((e: PageContentSidebarItem) => ({
          ...e,
          number: '0',
        }))
      }
      return tasksSidebarRoutes
    })

    return {
      openTemplateModal,
      tasksSidebarRoutesComputed,
      actionClick,
      openModal,
      isEmptyMode,
    }
  },
})
